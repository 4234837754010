import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import wardrobeImage from '../images/wardobe.jpg';
import wardrobeImage1 from '../images/wardrobev1.jpg';
import wardrobeImage2 from '../images/wardrobe2.jpg';
import wardrobeImage3 from '../images/wardrobe3.jpg';
import wardrobeImage4 from '../images/wardrobev2.jpg';
import Styles from './bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const WardrobeComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const wardrobeImages = [wardrobeImage, wardrobeImage1, wardrobeImage2, wardrobeImage3, wardrobeImage4];
  const wardrobe = {
    type: "Wardrobe Double Door With Mirror",
    height: "6 feet",
    length: "2.5 feet",
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <h1 style={{ textAlign: "center" }}>Wardrobe On Rent</h1>
      <div className={Styles.bedcontainer}>
        <MetaTags title={"Affordable & Stylish Wardrobe on Rent in Pune | Check Now"} description={"Transform your space with our stylish and budget-friendly wardrobe rentals in Pune. Don’t miss out! Check our amazing selection now for your perfect fit!"} />
        <div className={Styles.beditem}>
          <Swiper
            spaceBetween={1}
            slidesPerView={1}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1024: {
                slidesPerView: 1,
              },
              1366: {
                slidesPerView: 1,
              },
            }}
          >
            {wardrobeImages.map((image, index) => (
              <SwiperSlide key={index}>
                <img src={image} alt={`wardrobeImages ${index + 1}`} />
              </SwiperSlide>
            ))}
          </Swiper> <h2>{wardrobe.type}</h2>
          <h5 className={Styles.contactlink}><FaIndianRupeeSign />499/Month</h5>
          <Link to="/contactus" className={Styles.contactlink}> Contact Us</Link>
          <div className={Styles.whatsappicon}>
            <a href="https://wa.me/917276015298"><FaWhatsappSquare /></a>
            <a href="tel:+917276015298"><FaPhoneAlt /></a>
          </div>
          <button onClick={openPopup} className={Styles.button}>View Details</button>

          {showPopup && (
            <div className={Styles.popup}>
              <div className={Styles.popupcontent}>
                <span className={Styles.close} onClick={closePopup}>&times;</span>
                <h3 style={{ textAlign: "center" }}>Wardrobe On Rent</h3>
                <h2>Spacious {wardrobe.type}Double Door With Mirror for Organized Living</h2>
                <p>Keep your clothes and essentials neatly stored with our spacious Wardrobe Double Door With Mirror. Designed for functionality and style, this wardrobe is perfect for adding extra storage space to your bedroom, helping you stay organized without compromising on aesthetics.</p>
                <h3>Features:</h3>
                <ul>
                  <li><b>Size:</b>  Standard wardrobe with 2 or 3 doors (Customizable based on availability) </li>
                  <li><b>Material:</b>  High-quality wood/metal construction </li>
                  <li><b>Design: </b> Modern design with a smooth finish</li>
                </ul>
                <h3>Dimensions:</h3>
                <ul>
                  <li><b>Wardrobe Size:</b> Length 2.5 ft x Height 6 ft </li>
                </ul>
                <h3>Color and Style Options:</h3>
                <ul>
                  <li><b>Availability:</b>  Colors and styles may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
                </ul>
                <h3>Price: </h3>
                <ul>
                  <li><b>Monthly Rental:</b> <FaIndianRupeeSign /> 499/Month</li>
                  <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />1500/-</li>
                </ul>

                <h3>Benefits:</h3>
                <ul>
                  <li><b>Ample Storage:  </b> Includes multiple shelves and hanging space for all your clothes and accessories.</li>
                  <li><b>Durable Construction: </b> Built to last with high-quality materials.</li>
                  <li><b>Hassle-Free Service:</b> Includes free delivery and assembly.</li>
                </ul>
                <h3>Delivery and Installation:</h3>
                <ul>
                  <li>Free Delivery: Available throughout Pune.
                  </li>
                  <li>Free Installation: Professional setup included with your rental.</li>
                </ul>
                <h3>How to Order:</h3>
                <ul>
                  <li>Call Us: To rent this sofa, please call us. Our team will assist you with your order and any questions you may have.</li>
                </ul>

                <h3>Contact Information:</h3>
                <ul>
                  <li>Need More Info? <a href='https://furnituresonrent.com/contactus' target="_blank">Contact Us</a></li>
                </ul>
                <p>Price: <FaIndianRupeeSign />499/Month</p>
                <p>Height: {wardrobe.height}</p>
                <p>Length: {wardrobe.length}</p>
                <button className={Styles.button} onClick={closePopup}>Close</button>
              </div>
            </div>
          )}
        </div>
        <div className={Styles.container}>
          <div className={Styles.aboutText2}>
            <h2 className={Styles.aboutheading}>Wardrobe on Rent in Pune – Stylish & Convenient</h2>
            <p>Looking for a wardrobe on rent in Pune? Renting a wardrobe is a smart choice if you need extra storage without buying new furniture. At Furniture on Rent, we offer double door wardrobes with mirrors that are perfect for adding style and space to your home.</p>
            <h2 className={Styles.aboutheading}>Why Choose a Wardrobe on Rent in Pune?</h2>
            <ol>
              <li><b>Affordable:</b>
                Renting a wardrobe is much cheaper than buying one, making it a budget-friendly option.</li>
              <li><b> Flexible Rentals:</b>
                You can rent a wardrobe for as long as you need—whether it’s a few months or longer, we’ve got you covered.</li>
              <li><b>Fast Delivery:</b>
                If you’re searching for a wardrobe on rent near me, we deliver quickly across Pune, so you can get your wardrobe set up without delay.</li>
              <li><b>Stylish Design:</b>
                Our double door wardrobes with mirrors not only give you plenty of storage but also look great in your room.</li>
              <li><b> Easy Maintenance:</b>
                We take care of any repairs or maintenance, so you don’t have to worry about anything.</li>
              <li> Perfect for Temporary Living:
                Our wardrobes are ideal for students, professionals, or families staying in Pune for a short time. Enjoy flexible storage without the hassle of buying furniture.</li>
            </ol>
            <h2 className={Styles.aboutheading}>Types of Wardrobes Available for Rent in Pune</h2>
            <ul>
              <li><b> Double Door Wardrobe with Mirror:</b>
                We offer stylish double door wardrobes with mirrors that provide ample storage for all your clothes and accessories.</li>
            </ul>
            <h2 className={Styles.aboutheading}>Why Rent from Furniture on Rent?</h2>
            <ul>
              <li><b>Affordable Prices:</b> Get quality wardrobes at great rental rates.</li>
              <li><b>Flexible Plans:</b> Rent for as long as you need, with easy extensions available.</li>
              <li><b>Quick Delivery:</b> Need a wardrobe on rent in Pune? We’ll deliver it to your location fast.</li>
              <li><b>Stylish Options:</b> Our wardrobes are designed to meet your storage needs while looking good.</li>
            </ul>


            <h2 className={Styles.aboutheading}>Explore More Options</h2>
            <p>Looking to furnish your entire home? Check out our <a href='https://furnituresonrent.com/home-furniture' target='_blank'>Home Furniture on Rent</a> and <a href='https://furnituresonrent.com/home-appliances' target='_blank'>Home Appliances on Rent</a> to complete your setup easily.</p>
            <p><a href='https://furnituresonrent.com/contactus' target='_blank'>Contact</a> Us to rent the perfect wardrobe in Pune and enjoy stylish storage delivered right to your door!</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WardrobeComponent;

// TrendingProducts.js

import React from 'react';
import styles from './trendingProducts.module.css';
import HomeFurniture from '../images/homeFurniture.png';
import HomeAppliances from '../images/Fridge_and_Washing_Machine_Combo_500x350.webp'
import MetaTags from '../../component/metaTag.js';

const TrendingProducts = () => {
  return (
    <section id="trendingProductSection" className={styles.TrendingProducts}>
      <MetaTags title={"Trending Furniture & Appliances on Rent in Pune | Explore Now!"} description={"Explore the best in trending furniture and appliances for rent in Pune! Find stylish solutions for your home and enjoy comfort without the commitment!"} />
      <h1 className={styles.h2}>Trending Products On Rent</h1>
      <p className={styles.p1}>
        Furniture on Rent presents a distinctive collection of stylish, contemporary, and ultra-modern furniture available at affordable rental rates. Committed to providing an extensive selection of home furniture and appliances, our offerings are intelligently designed to blend comfort with functionality.
      </p>

      <div className={styles.productContainer}>
        {/* Home Appliances Box */}
        <div className={styles.productBox}>
          <img src={HomeAppliances} alt="Home Appliance" />
          <h3>Household Appliance Essentials</h3>
          <p>We offer a comprehensive range of home appliances and furniture for rent across Pune, ensuring no compromise with your lifestyle and comfort. Simply pay and use – our easy and stress-free service makes the entire rental process seamless. Experience the convenience of Appliances On Rent, where your satisfaction is our priority.</p>
        </div>

        {/* Home Furniture Box */}
        <div className={styles.productBox}>
          <img src={HomeFurniture} alt="Home Furniture" />
          <h3>Household Furnishing Solutions</h3>
          <p>Discover the perfect blend of aesthetics, variety, dimensions, functionalities, and affordability in our curated collection of rental furniture. Whether you prefer modern or traditional designs, minimalistic or intricate details, muted or vibrant colors, compact or spacious sizes, we provide a comprehensive range to complement your interior design seamlessly.</p>
        </div>
      </div>
    </section>
  );
};

export default TrendingProducts;

import React, { useState } from 'react';
import { FaIndianRupeeSign } from "react-icons/fa6";
import singleFridge from '../images/singleFridge1.jpg';
import singleFridge1 from '../images/SingleDoorFridgee.jpg';
import singleFridge3 from '../images/SingleDoorFridge1.jpg';
import singleFridge2 from '../images/singledoorfridge2.jpg';
import doubleFridge1 from '../images/doubledoorfridge3.jpg';
import doubleFridge2 from '../images/doubledoorfridge2.webp';
import doubleFridge3 from '../images/doubledoorfridge1.jpg';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import { FaWhatsappSquare, FaPhoneAlt } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Styles from '../homeFurniture/bedComponent.module.css';
import MetaTags from '../../component/metaTag.js';

const FridgeComponent = () => {
  const [showSingleDoorPopup, setShowSingleDoorPopup] = useState(false);
  const [showDoubleDoorPopup, setShowDoubleDoorPopup] = useState(false);
  const singleFridgeImage = [singleFridge1, singleFridge, singleFridge3, singleFridge2];
  const doubleDoorFridgeImage = [doubleFridge1, doubleFridge2, doubleFridge3]
  const singleDoorFridge = {
    type: "Single door",
    starRating: "3 to 4 star",
    capacity: "180 to 200 liters",
    name: "Single Door Fridge",
    description: "Fridge 180L and 200L"
  };

  const doubleDoorFridge = {
    type: "Double door",
    starRating: "3 to 5 star",
    capacity: "230 to 300 liters",
    name: "Double Door Fridge",
    description: "Fridge 230L and 300L"
  };

  const openSingleDoorPopup = () => {
    setShowSingleDoorPopup(true);
  };

  const openDoubleDoorPopup = () => {
    setShowDoubleDoorPopup(true);
  };

  const closeSingleDoorPopup = () => {
    setShowSingleDoorPopup(false);
  };

  const closeDoubleDoorPopup = () => {
    setShowDoubleDoorPopup(false);
  };

  return (
    <>
    <h1 style={{ textAlign: "center" }}>Fridge On Rent</h1>
    <div className={Styles.bedcontainer}>
      <MetaTags title={"Quality Furniture on Rent in Pune | Check Great Deals Today"} description={"Fridge on rent in Pune at affordable rates! Our easy rental process ensures you get the best cooling solution. Start your rental journey today!"} />
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {singleFridgeImage.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`singleFridgeImage ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h2>{singleDoorFridge.name} <span>({singleDoorFridge.capacity})</span></h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />499/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>Contact Us</Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298"><FaWhatsappSquare /></a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button onClick={openSingleDoorPopup} className={Styles.button}>View Details</button>

        {showSingleDoorPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closeSingleDoorPopup}>&times;</span>
              <h1>{singleDoorFridge.name}</h1>
              <h2>Compact Single Door Fridge for Efficient Coolin</h2>
              <p>Keep your food fresh and beverages chilled with our compact single door fridge. Ideal for small kitchens, bachelor pads, or office pantries, this fridge offers reliable cooling in a space-saving design.</p>
              <h3>Features:</h3>
              <ul>
                <li><b>Capacity:</b> Available in 180L and 200L options (Customizable based on availability)</li>
                <li><b>Design:</b> Single door with a spacious interior and adjustable shelves</li>
                <li><b>Functionality:</b> Energy-efficient with fast cooling, ice tray, and vegetable crisper</li>
                <li><b>Star Rating:</b> 3 to 4 Star</li>
              </ul>
              <h3>Brand and Model Options:</h3>
              <ul>
                <li><b>Availability:</b> Brands and models may vary based on current stock. We will do our best to accommodate your preferences based on availability.
                </li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> 499/Month</li>
                <li><b>One-Time Security Deposit:</b> 1500/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Compact Design: </b> Fits easily into smaller spaces without sacrificing storage capacity.
                </li>
                <li><b>Cost-Effective: </b>  A budget-friendly option for short-term or long-term use.
                </li>
                <li><b>Hassle-Free Service:</b> Includes free delivery and installation.
                </li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this single door fridge, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Type: {singleDoorFridge.type}</p>
              <p>Price:<FaIndianRupeeSign />499/Month</p>
              <p>Star Rating: {singleDoorFridge.starRating}</p>
              <p>Description: {singleDoorFridge.description}</p> */}
              <button className={Styles.button} onClick={closeSingleDoorPopup}>Close</button>
            </div>
          </div>

        )}
      </div>
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        > {doubleDoorFridgeImage.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`doubleDoorFridgeImage${index + 1}`} />
          </SwiperSlide>
        ))}
        </Swiper>
        <h2>{doubleDoorFridge.name} <span>({doubleDoorFridge.capacity})</span></h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />799/Month</h5>
        <Link to="/contactus" className={Styles.contactlink}>Contact Us</Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298">
            <FaWhatsappSquare />
          </a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button onClick={openDoubleDoorPopup} className={Styles.button} >View Details</button>

        {showDoubleDoorPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closeDoubleDoorPopup}>&times;</span>
              <h1>{doubleDoorFridge.name} for Rent</h1>
              <p>Spacious Double Door Fridge for Ample Storage and Efficient Cooling</p>
              <p>Upgrade your kitchen with our double door fridge, designed to provide ample storage and superior cooling performance. Perfect for families or shared accommodations, this fridge offers the convenience of separate freezer and fridge compartments, ensuring your food stays fresh and organized.</p>
              <h3>Features:</h3>
              <ul>
                <li><b>Capacity:</b> Available in 230L and 300L options (Customizable based on availability)</li>
                <li><b>Design:</b> Double door with separate freezer compartment, adjustable shelves, and large vegetable crisper</li>
                <li><b>Functionality:</b> Energy-efficient cooling, quick freeze feature, and frost-free operation</li>
                <li><b>Star Rating:</b> 3 to 4 Star</li>
              </ul>
              <h3>Features:</h3>
              <ul>
                <li><b>Capacity:</b> Available in 230L and 300L options (Customizable based on availability)</li>
                <li><b>Design:</b> Double door with separate freezer compartment, adjustable shelves, and large vegetable crisper</li>
                <li><b>Functionality:</b> Energy-efficient cooling, quick freeze feature, and frost-free operation</li>
                <li><b>Star Rating:</b> 3 to 4 Star</li>
              </ul>
              <h3>Brand and Model Options:</h3>
              <ul>
                <li><b>Availability:</b> Brands and models may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> 799/Month</li>
                <li><b>One-Time Security Deposit:</b> 3500/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Ample Storage: </b> Plenty of space to store food for families or group living.</li>
                <li><b>Separate Freezer: </b>  Dedicated freezer compartment for better organization.</li>
                <li><b>Easy Service:</b> We handle delivery and installation for you, free of charge.</li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.</li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this double door fridge, please call us. Our team will assist you with your order and any questions you may have.</li>
                <li>Call Us: To rent this double door fridge, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Type: {doubleDoorFridge.type}</p>
              <p>Price: <FaIndianRupeeSign />799/Month</p>
              <p>Star Rating: {doubleDoorFridge.starRating}</p>
              <p>Description: {doubleDoorFridge.description}</p> */}
              <button className={Styles.button} onClick={closeDoubleDoorPopup}>Close</button>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.container}>
        <div className={Styles.aboutText2}>
          <h2 className={Styles.aboutheading}>Keep Your Food Fresh: Rent a Fridge in Pune</h2>
          <p>Need a fridge without the cost of buying one? Renting a fridge on rent in Pune is a smart choice. With our fridge rental service, you can get a great appliance without the high price. It’s perfect if you want to save money and avoid the hassle of owning a fridge.</p>
          <h2 className={Styles.aboutheading}>Why Rent a Fridge?</h2>
          <p>Renting a fridge on rent in Pune is easy and flexible. We have a variety of fridges, including single-door and double-door models. Whether you need a small fridge for a tiny space or a large one for a big family, we’ve got you covered. Our rental plans let you choose how long you need the fridge, so you can pick what works best for you.</p>
          <h2 className={Styles.aboutheading}>What We Offer</h2>
          <ul>
            <li><b>Free Delivery and Setup:</b> We’ll deliver the fridge on rent in Pune to your home and set it up for you.
            </li>
            <li><b>Flexible Rental Plans:</b> Choose a plan that fits your needs, whether you want the fridge on rent in Pune for a few months or longer.
            </li>
            <li><b>Different Models:</b> Pick from single-door or double-door fridges, depending on your needs.
            </li>
          </ul>
          <h2 className={Styles.aboutheading}>Find Fridges on Rent Near You </h2>
          <p>If you’re searching for a fridge on rent in Pune, we deliver to areas like Shivaji Nagar, Koregaon Park, Boat Club Road, Kothrud, New Kalyani Nagar, Erandwane, Senapati Bapat Road, and Model Colony.</p>
          <p>With our simple online process, you can choose and get a fridge on rent in Pune delivered to your home easily. Enjoy a stress-free experience with our rental service.</p>
          <h2 className={Styles.aboutheading}>Rent Your Fridge Online Today!</h2>
          <p>Make keeping your food fresh easy with our fridge on rent in Pune. Save time and skip the maintenance with a high-quality fridge. Check out our options and start your fridge on rent in Pune today!</p>
        </div>
      </div>
    </div>
    </>
  );
};

export default FridgeComponent;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaWhatsappSquare, FaPhoneAlt } from "react-icons/fa";
import { FaIndianRupeeSign } from "react-icons/fa6";
import washingmachine4 from '../images/washingmachinefull.jpg';
import washingmachine5 from '../images/washingmachinenew.jpg';
import washingmachine from '../images/washingmachine1.jpg';
import washingmachine1 from '../images/washingmachine.jpg';
import washingmachine2 from '../images/washingmachine2.jpg';
import washingmachine3 from '../images/washingmachine3.jpg';
import Styles from '../homeFurniture/bedComponent.module.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import MetaTags from '../../component/metaTag.js';
const WashingMachineComponent = () => {
  const [showPopup, setShowPopup] = useState(false);
  const washingMachineImage = [washingmachine4, washingmachine5, washingmachine, washingmachine1, washingmachine2, washingmachine3];
  const washingMachine = {
    type: "Top load",
    capacity: "6.2 kg to 6.5 kg",
    name: "Washing Machine",
    description: "6.2 kg and 6.5 kg Top Load Washing Machine"
  };

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (<>
    <h1 style={{ textAlign: "center" }}>Washing Machine On Rent</h1>
    <div className={Styles.bedcontainer}>
      <MetaTags title={"Washing Machine on Rent in Pune | Check Now, Rent Today!"} description={"Looking for a washing machine on rent in Pune? We offer great prices and flexible options. Check out our range and rent your machine today!"} />
      <div className={Styles.beditem}>
        <Swiper
          spaceBetween={1}
          slidesPerView={1}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 1,
            },
            1366: {
              slidesPerView: 1,
            },
          }}
        >
          {washingMachineImage.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt={`washingMachineImage ${index + 1}`} />
            </SwiperSlide>
          ))}
        </Swiper>
        <h2>{washingMachine.name}</h2>
        <h5 className={Styles.contactlink}><FaIndianRupeeSign />499/Month</h5>
        <p>{washingMachine.type}</p>
        <Link to="/contactus" className={Styles.contactlink}>Contact Us</Link>
        <div className={Styles.whatsappicon}>
          <a href="https://wa.me/917276015298"><FaWhatsappSquare /></a>
          <a href="tel:+917276015298"><FaPhoneAlt /></a>
        </div>
        <button onClick={openPopup} className={Styles.button}>View Details</button>

        {showPopup && (
          <div className={Styles.popup}>
            <div className={Styles.popupcontent}>
              <span className={Styles.close} onClick={closePopup}>&times;</span>
              <h1>{washingMachine.name} for Rent</h1>
              <h2>Efficient Washing Machine for Hassle-Free Laundry</h2>
              <p>Simplify your laundry routine with our high-performance top-load washing machine. Perfect for singles, couples, and families, it offers convenience and efficiency, ensuring your clothes are cleaned thoroughly with minimal effort.
              </p>
              <h3>Features:</h3>
              <ul>
                <li><b>Capacity:</b>  Available in 6.2 kg, and 6.5 kg options (Customizable based on availability)
                </li>
                <li><b>Type:</b> Fully automatic/semi automatic models available
                </li>
                <li><b>Functionality:</b> Multiple wash programs, quick wash, and energy-efficient design</li>
              </ul>
              <h3>Brand and Model Options:</h3>
              <ul>
                <li><b>Availability:</b> Brands and models may vary based on current stock. We will do our best to accommodate your preferences based on availability.</li>
              </ul>
              <h3>Price: </h3>
              <ul>
                <li><b>Monthly Rental:</b> <FaIndianRupeeSign />499/Month</li>
                <li><b>One-Time Security Deposit:</b> <FaIndianRupeeSign />1500/-</li>
              </ul>

              <h3>Benefits:</h3>
              <ul>
                <li><b>Convenience: </b> Save time and effort with an automatic washing machine.</li>
                <li><b>Cost-Effective: </b>Affordable rental option compared to buying new. </li>
                <li><b>Hassle-Free Service:</b> Includes free delivery and installation.
                </li>
              </ul>
              <h3>Delivery and Installation:</h3>
              <ul>
                <li>Free Delivery: Available throughout Pune.
                </li>
                <li>Free Installation: Professional setup included with your rental.</li>
              </ul>
              <h3>How to Order:</h3>
              <ul>
                <li>Call Us: To rent this washing machine, please call us. Our team will assist you with your order and any questions you may have.</li>
              </ul>

              <h3>Contact Information:</h3>
              <ul>
                <li>Need More Info? Contact Us</li>
              </ul>
              {/* <p>Price: <FaIndianRupeeSign />499/Month</p>
              <p>{washingMachine.type}</p>
              <p>Description: {washingMachine.description}</p> */}
              <button className={Styles.button} onClick={closePopup}>Close</button>
            </div>
          </div>
        )}
      </div>
      <div className={Styles.container}>
        <div className={Styles.aboutText2}>
          <h2 className={Styles.aboutheading}>Say Goodbye to Laundry Worries: Rent a Washing Machine in Pune</h2>
          <p>Looking for an easy way to manage your laundry? Renting a washing machine in Pune is a smart choice. With our washing machine rental service and <a href="https://furnituresonrent.com/home-furniture" target="_blank">Home Appliances</a> options, you can get a great washing machine without the high cost of buying one. It’s perfect for anyone who wants to save money and avoid the complications of owning a machine.</p>
          <h2 className={Styles.aboutheading}>Why Rent a Washing Machine?</h2>
          <p>Renting is convenient and flexible. We offer a variety of machines, including fully automatic, semi-automatic, front load, and top load models. Whether you have a small space or need something for a big family, we have options that fit your needs. Our rental plans let you choose how long you want the machine, so you can adjust based on what works best for you.</p>
          <h2 className={Styles.aboutheading}>Work from Home in Comfort</h2>
          <p>With the rise of remote work, having a well-organized and comfortable workspace at home is more important than ever. Our study tables on rent in Pune are ideal for creating a dedicated work area that helps you stay productive. Pairing a study table with an office chair provides ergonomic support, reducing the strain of long hours at the desk. It's a great way to enhance your work-from-home experience without the upfront costs of buying furniture.</p>
          <h2 className={Styles.aboutheading}>What We Offer</h2>
          <ul>
            <li><b>Free Delivery and Setup:</b> We’ll bring the washing machine right to your home and set it up for you. No need to worry about moving or installing it yourself.</li>
            <li><b>Flexible Rental Plans:</b> Choose a plan that fits your schedule and budget, whether you need a machine for a few months or longer.</li>
            <li><b>Variety of Models:</b> From compact machines for small spaces to larger ones for bigger families, we’ve got something for everyone.</li>
          </ul>
          <h2 className={Styles.aboutheading}>Find Washing Machines Near You</h2>
          <p>If you’re searching for a washing machine on rent in Pune or washing machines on rent near me, we’ve got you covered in areas like Shivaji Nagar, Koregaon Park, Boat Club Road, Kothrud, New Kalyani Nagar, Erandwane, Senapati Bapat Road, and Model Colony.</p>
          <p>With our easy online rental process, you can choose and get a washing machine delivered to your home without any fuss. Enjoy a simple and stress-free laundry experience with our rental service.</p>
          <h2 className={Styles.aboutheading}>Rent Your Washing Machine Online Today!</h2>
          <p>Make laundry easier with our washing machine rentals. Save time and avoid maintenance issues while enjoying a top-quality machine. Check out our options and get started today!</p>
        </div>
      </div>
    </div>
  </>
  );
};

export default WashingMachineComponent;

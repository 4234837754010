// HomeFurniture.js
import React from 'react';
import styles from './homeFurniture.module.css';
import { Link } from 'react-router-dom';
import bedImage from '../images/homeFurniture.png';
import wardrobeImage from '../images/wardrobe.jpg';
import sofa from '../images/sofaaa.png';
import onebhk from '../images/1bhkimage.jpg';
import studyTableImage from '../images/studytable.jpg';
import MetaTags from '../../component/metaTag.js';
import { Helmet } from 'react-helmet';

const HomeFurniture = () => {
  return (
    <div className={styles.container}>
      <MetaTags title={"Best Quality Furniture On Rent in Pune | Great Deals & Easy Rental"} description={"Looking for affordable & cheap furniture on rent in Pune? Check our budget-friendly options and give your living space a stylish upgrade today."} />
        <Helmet>
          <script type="application/ld+json" async>
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "Organization",
              name: "Furniture On Rent - Home Furniture",
              url: "https://furnituresonrent.com/home-furniture",
              logo: "https://furnituresonrent.com/static/media/logo.43f817df1d46fe7a2347.png",
              contactPoint: [
                {
                  "@type": "ContactPoint",
                  telephone: "+91-727615298",
                  contactType: "Customer Service",
                  availableLanguage: "en",
                  areaServed: "Pune, Maharashtra, IN"
                }
              ],
              address: {
                "@type": "PostalAddress",
                streetAddress: "No-3, House no, Sadguru Colony, a-13, Wakad Rd, Shankar Kalat Nagar, Wakad",
                addressLocality: "Pune",
                addressRegion: "Maharashtra",
                postalCode: "411057",
                addressCountry: "IN"
              },
              email: "pingale.ganesh431@gmail.com",
              telephone: "+91-727615298",
              founder: {
                "@type": "Person",
                name: "Ganesh Pingale"
              },
              department: {
                "@type": "Department",
                name: "Home Furniture Rentals",
                url: "https://furnituresonrent.com/home-furniture",
                description: "Rent beds, wardrobes, study tables, sofas, and more with affordable pricing and easy rental terms in Pune.",
                areaServed: "Pune, Maharashtra",
                contactPoint: {
                  "@type": "ContactPoint",
                  telephone: "+91-727615298",
                  contactType: "Sales"
                }
              }
            })}
          </script>
      </Helmet>
      <h1 className={styles.heading}>Home Furniture On Rent</h1>
      <div className={styles.subcontainer}>
        <Link to='/home-furniture/bed' className={styles.box}>
          <img src={bedImage} alt="Bed" className={styles.image} />
          <h3 className={styles.heading}>Bed</h3>
        </Link>
        <Link to='/home-furniture/wardrobe' className={styles.box}>
          <img src={wardrobeImage} alt="Wardrobe" className={styles.image} />
          <h3 className={styles.heading}>Wardrobe</h3>
        </Link>
        <Link to='/home-furniture/study-table' className={styles.box}>
          <img src={studyTableImage} alt="Study Table" className={styles.image} />
          <h3 className={styles.heading}>Study Table</h3>
        </Link>
        <Link to='/home-furniture/sofa' className={styles.box}>
          <img src={sofa} alt="Sofa" className={styles.image} />
          <h3 className={styles.heading}>Sofa</h3>
        </Link>
        <Link to='/home-furniture/combo-rent' className={styles.box}>
          <img src={onebhk} alt="Combo-rent-pack" className={styles.image} />
          <h3 className={styles.heading}>1-BHK Combo Rent Pack
          </h3>
        </Link>
      </div>
      <div className={styles.aboutText2}>
        <h1>Furniture on Rent in Pune: Affordable Solutions for Your Home</h1>
        <p>Furnishing your home in Pune is now more affordable and convenient with Furniture on Rent Pune. We provide a wide range of rental furniture in Pune to meet all your needs, whether you're setting up a new place or refreshing your current space. Our selection includes essential <a href="https://furnituresonrent.com/trending-product" target="_blank">Trending Products</a> like beds, wardrobes, study tables, and sofas. We also offer a 1 BHK Combo Rent Pack, perfect for those looking to furnish an entire apartment. Explore our options, add what you need to your cart, and check out—all online.
          We deliver rental furniture Pune-wide, covering popular areas such as Baner, Koregaon Park, Kalyani Nagar, and Kothrud. Delivery and installation are free, and we coordinate with you to ensure a smooth experience. With a minimum rental period of just three months, renting furniture in Pune offers the flexibility you need without a long-term commitment.</p>
        <h1>Affordable & Quality Furniture on Rent For Every Room In Your Home</h1>
        <p>Furnishing your home in Pune is easy with our rental options! We provide high-quality, affordable furniture to make your life simpler. Whether you're moving into a new place, setting up a temporary space, or just looking to upgrade, we have the perfect solutions for you. Here’s what we offer:</p>
        <h3>Beds on Rent in Pune:</h3>
        <p>Sleep well with our <a href='https://furnituresonrent.com/home-furniture/bed' target="_blank">Bed</a> on rent in Pune. We have single, double, and queen sizes to fit your room. Each bed is comfy and sturdy, perfect for a restful night.</p>
        <h3>Wardrobes on Rent in Pune:</h3>
        <p>Keep your clothes organized with our <a href="https://furnituresonrent.com/home-furniture/wardrobe" target="_blank">Wardrobe</a> on rent in Pune. Choose from various sizes and styles. Our wardrobes have adjustable shelves and lots of hanging space, so you can keep everything neat.</p>
        <h3>Study Tables on Rent in Pune:</h3>
        <p>Set up a great workspace with our <a href='https://furnituresonrent.com/home-furniture/study-table' target="_blank">Study table</a> on rent in Pune. Whether for studying or working, these tables have plenty of space for your books and laptop. They’re practical and comfortable.</p>
        <h3>Sofas on Rent in Pune:</h3>
        <p>Make your living room cozy with our <a href='https://furnituresonrent.com/home-furniture/sofa' target="_blank">Sofa</a> on rent in Pune. We offer everything from small two-seaters to large L-shaped sofas. Relax or entertain guests with our comfy and stylish options.</p>
        <h3>1 BHK Combo Rent Pack:</h3>
        <p>Furnish your whole 1 BHK easily with our 1 BHK <a href='https://furnituresonrent.com/home-furniture/combo-rent' target="_blank">Combo Rent</a> Pack. This package includes a bed, wardrobe, study table, and sofa, so you can set up your home quickly and affordably.</p>

        <h2>Convenient Furniture on Rent Near Me</h2>
        <p>Searching for furniture on rent near me? With our extensive delivery network across Pune, you can easily access high-quality furniture on rent no matter where you are in the city. Whether you're in Pimpri Chinchwad,  Baner, Wakad, Bavdhan or any other neighborhood, we've got you covered.</p>
        <h2>Why Rent Furniture from Us Instead of Buying Second-Hand?</h2>
        <ul>
          <li>Reliable Quality: Our furniture is carefully checked and maintained, so you get high-quality pieces in great condition. With second-hand furniture, you can’t always be sure of its quality or durability.</li>
          <li>Convenient Service: We provide free delivery, setup, and relocation. When you buy second-hand, you often have to arrange and pay for these services yourself.</li>
          <li>No Extra Costs: We cover all maintenance and repairs at no additional cost. Second-hand furniture might require you to pay for repairs and upkeep.</li>
          <li>Flexible Upgrades: Renting allows you to easily swap or upgrade your furniture as your needs change. Buying second-hand usually means you’re stuck with your choice for a longer time.</li>
          <li>Cost-Effective: Renting is often more affordable than buying new or second-hand furniture and includes all necessary services.</li>
        </ul>
        <h2>Upgrade Your Home with Furniture on Rent in Pune</h2>
        <p>Renting furniture in Pune is an easy way to improve your living space without spending a lot. Whether you need furniture for a short time or longer, renting gives you flexibility and convenience. Try our affordable furniture rental service today and make your home look great! And don’t forget to check out our <a href='https://furnituresonrent.com/home-appliances' target="_blank">Home Appliances</a> on rent for a complete home setup.</p>

      </div>
    </div>
  );
};

export default HomeFurniture;

import React from 'react';
import styles from './ContactUs.module.css';
import { MdEmail } from "react-icons/md";
import { FaAddressCard, FaPhoneAlt, FaWhatsappSquare } from "react-icons/fa";
import MetaTags from '../../component/metaTag.js';

const ContactUs = () => {
    return (
        <section className={styles.ContactUs}>
            <MetaTags title={"Contact Us-Furniture On Rent Pune"} description={"Rent furniture easily in Pune! Contact us for a variety of stylish and affordable options. We’re here to help you upgrade your space!"} />
        <h1 style={{ textAlign: "center" }} className={styles.aboutheading}>Contact Us</h1>
            <div className=''>
                <p>We’re here to help! Whether you have a question about our furniture and appliance rentals, need assistance with your order, or just want to say hello, feel free to reach out to us through any of the following ways:</p>
            </div>

            <div className={styles.contactinfo}>
                <div className={styles.infoitem}>
                    <div className={styles.itemicon}>
                        <FaAddressCard className={styles.iconAddress} />
                        <span><b>Visit Us:</b></span>
                    </div>

                    <span>No-3, House no Sadguru Colony, a-13, Wakad Rd, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra 411057.</span>
                </div>

                <div className={styles.infoitem}>
                    <div className={styles.itemicon}>
                        <FaPhoneAlt className={styles.iconPhone} />
                        <span><b>Call Us: </b></span>
                    </div>
                    <a href="tel:+917276015298">7276015298</a>
                </div>

                <div className={styles.infoitem}>
                    <div className={styles.itemicon}>
                        <FaWhatsappSquare className={styles.iconWhatsapp} />
                        <span><b>WhatsApp Us:</b></span>
                    </div>
                    <a href="https://wa.me/917276015298">WhatsApp</a>
                </div>

                <div className={styles.infoitem}>
                    <div className={styles.itemicon}>
                        <MdEmail className={styles.iconMail} />
                        <span> <b>Email Us:</b></span>
                    </div>
                    <a href="mailto:pingale.ganesh431@gmail.com">pingale.ganesh431@gmail.com</a>
                </div>
            </div>
            <div className=''>
                <p>We strive to respond as quickly as possible and ensure you get the assistance you need. We look forward to hearing from you!</p>
            </div>

        </section>
    );
};

export default ContactUs;
